import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import darkMode from '../images/Dark Mode Text.png';
import lightMode from '../images/Light Mode Text.png';
import DropDown from './DropDown';
import HeaderLinks from './HeaderLinks';
import DarkToggle from './DarkToggle';
import { linkList } from '../util/linkList';
import { ThemeContext } from './themeContext';

function Navbar(colors) {
    const theme = useContext(ThemeContext).theme;
    console.log(theme);
    return (
        <div className={`flex justify-end ${colors.styles} shadow-md sticky top-0`}>
            <nav className="h-auto flex w-screen py-2 justify-start">
                <div id="brand" className="pr-4 pl-3 mr-4 flex items-center">
                    <Link to="/">
                        {theme === 'dark' ? <img src={darkMode} alt="Logo" className="h-16 w-16 mr-3" /> :
                            <img src={lightMode} alt="Logo" className="h-16 w-16 mr-3" />}
                    </Link>
                </div>
            </nav>
            <DarkToggle />
            <DropDown routes={linkList} />
            <HeaderLinks routes={linkList} />
        </div>
    )
}

export default Navbar;